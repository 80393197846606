import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { HttpService } from "src/app/core/services/http.service";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deepFreeze } from '../../core/utils';

@Injectable()
export class PresentationItemService extends HttpService<app.operation.ProjectTaskPresentationItemListViewModel, app.operation.ProjectTaskPresentationItemInsertUpdateViewModel, app.operation.ProjectTaskPresentationItemInsertUpdateViewModel>
{
    url: string;
    objectKey = "op.ProjectTaskPresentationItem";

    constructor(http: HttpClient) {
        super(http, "/api/operation/projecttaskpresentationitem/");
    }

    query(id: number, parameters?: any): Observable<PagedList<app.operation.ProjectTaskPresentationItemListViewModel>> {
        return this.http
            .get<PagedList<app.operation.ProjectTaskPresentationItemListViewModel>>(this.getUrl(id), {
                params: this.getParameters(parameters)
            }).pipe(
                map<any, PagedList<app.operation.ProjectTaskPresentationItemListViewModel>>(res => {
                    return deepFreeze(res);
                }));
    }

    get(projectTaskId: number, id: any, appendLists: boolean, useQueryString?: boolean): Observable<app.operation.ProjectTaskPresentationItemInsertUpdateViewModel> {
        let params = new HttpParams();
        if (appendLists) {
            params = params.set("appendLists", appendLists.toString());
        }
        if (useQueryString) {
            params = params.set("id", id);
        }
        return this.http.get<app.operation.ProjectTaskPresentationItemInsertUpdateViewModel>(this.getUrl(projectTaskId) + '/' + id, {
            params
        });
    }


    save(id: number, model: app.operation.ProjectTaskPresentationItemInsertUpdateViewModel): Observable<app.operation.ProjectTaskPresentationItemInsertUpdateViewModel[]> {
        return this.http.post<app.operation.ProjectTaskPresentationItemInsertUpdateViewModel[]>(this.getUrl(id), model);
    }

    saveChanges(projectTaskId: number, id: number, model: app.operation.ProjectTaskPresentationItemInsertUpdateViewModel): Observable<app.operation.ProjectTaskPresentationItemInsertUpdateViewModel[]> {
        return this.http.put<app.operation.ProjectTaskPresentationItemInsertUpdateViewModel[]>(this.getUrl(projectTaskId) + '/' + id, model);
    }

    getUrl(id: number) {
        return `/api/operation/projectTask/${id}/presentationitem`;
    }

    deleteById(id: number, projectTaskId: number): Observable<any> {
        return this.http.delete(this.getUrl(projectTaskId) + '/' + id);
    }


}
