
<af-page-bar title="{{ 'hollen.clientapp.src.app.operation.presentation-item.presentation-item-list.component.presentation items' | translate }}">
</af-page-bar>

<af-page-filter [control]="afTable" [loading]="isLoading" [params]="queryParams" (filter)="loadData()">
    <div class="form-group">
        <input type="text" class="form-control me-sm-1" [(ngModel)]="queryParams.searchTerm" placeholder="{{ 'filter.search' | translate }}" />
    </div>
</af-page-filter>

<af-table #afTable [value]="model" [loading]="isLoading" [params]="queryParams" [sortBy]="sortBy" (load)="loadData()">
    <af-column field="projectTaskPresentationItemId" sortable="projectTaskPresentationItemId" header="{{ 'hollen.operation.projecttaskpresentationitemlistviewmodel.id' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [routerLink]="['/operation/projectTask', item.firstProjectTaskId, 'presentation-item', item.projectTaskPresentationItemId]">{{item.projectTaskPresentationItemId}}</a>
        </ng-template>
    </af-column>
    <af-column field="recordOrder" sortable="recordOrder" header="{{ 'hollen.operation.projecttaskpresentationitemlistviewmodel.order' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.recordOrder}}
        </ng-template>
    </af-column>
    <af-column field="length" sortable="length" header="{{ 'hollen.operation.projecttaskpresentationitemlistviewmodel.length' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            {{item.length }}
        </ng-template>
    </af-column>
    <af-column field="fileName" sortable="fileName" header="{{ 'hollen.operation.projecttaskpresentationitemlistviewmodel.filename' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <a [href]="item.fileUrl" target="_blank">{{item.fileName}}</a>
        </ng-template>
    </af-column>
    <af-column field="preview" header="{{ 'hollen.clientapp.src.app.operation.presentation-item.presentation-item-list.component.preview' | translate }}">
        <ng-template let-item="rowData" afTemplate>
            <img [src]="item.linkUrl" [style]="{'max-width.px':100, 'max-height.px': 100}" [width]="100"/>
        </ng-template>
    </af-column>
</af-table>
