import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/internal/Observable';
import { HttpService } from "../core/services/http.service";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class MetriqSyncService
    extends HttpService<app.metriqsync.SyncQueueListViewModel, app.metriqsync.SyncQueueListViewModel, app.metriqsync.SyncQueueListViewModel>
{
    objectKey = "mq.MetriqSync";
    url: string;

    constructor(http: HttpClient) {
        super(http, "/api/metriqsync/metriqsync/");
    }

    getActiveUsers(projectTaskId: number): Observable<app.metriqsync.GetOrderUserListViewModel[]> {
        return this.http.get<app.metriqsync.GetOrderUserListViewModel[]>(this.url + projectTaskId + "/active-users");
    }

    getActiveOrUnsyncedWorkreports(projectTaskId: number): Observable<app.metriqsync.ActiveOrUnsyncedWorkReportsViewModel> {
        return this.http.get<app.metriqsync.ActiveOrUnsyncedWorkReportsViewModel>(this.url + projectTaskId + "/active-unsynced");
    }

    deleteWorkTimeInMetriq(guid: string) {
        return this.http.delete(this.url + "deleteUnsyncedWorkTime/" + guid);
    }

    deleteComponentInMetriq(componentId: number) {
        return this.http.get(this.url + componentId + "/delete-component-metriq");
    }

    endSortingMQI(workPlaceId: number, mqUserGuid: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.set("showToast", "true");
        return this.http.put<boolean>(this.url + workPlaceId + "/endSorting/" + mqUserGuid, null, { params });
    }

    syncFile(item: app.sharepoint.SharePointFileViewModel) {
        let params = new HttpParams();
        params = params.set("showToast", "false");
        return this.http.put<app.sharepoint.SharePointFileViewModel>(this.url + "syncFile", item, { params });
    }

    getFileVersions(projectTaskId: number) {
        return this.http.get<app.metriqsync.GetOrderDocumentViewModel[]>(this.url + "getFileVersions/" + projectTaskId);
    }

    getFileList(projectTaskId: number) {
        return this.http.get<app.metriqsync.GetOrderDocumentViewModel[]>(this.url + "getFileList/" + projectTaskId);
    }

    updateWorkTimeDirectly(model: app.operation.WorkTimeUpdateViewModel): Observable<any> {
        return this.http.put(this.url + "updateWorkTimeDirectly", model);
    }

    postSyncEvent(id: string, eventType: string): Observable<any> {
        const url = this.url + "addSyncEvent/" + id + "/" + eventType;
        return this.http.post<any>(url, null);
    }

}




