import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../core/services/http.service";
import { FileUploadOptions } from "../../shared/components/file-upload/file-upload.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { getUnixDate } from "../../core/utils";
import { TimeObject } from "../../models/time-object.model";

@Injectable()
export class ProjectTaskService
    extends HttpService<app.operation.ProjectTaskListViewModel,
        app.operation.ProjectTaskFormViewModel,
        app.operation.ProjectTaskInsertViewModel | app.operation.ProjectTaskUpdateViewModel>
{
    objectKey = "op.ProjectTask";

    constructor(http: HttpClient) {
        super(http, "/api/operation/projecttask/");
    }

    getCompanyClusters(page?: number, pageSize?: number, searchTerm?: string): Observable<string[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }

        return this.http.get<string[]>(this.url + "companyclusters", { params });
    }

    getListItem(id: number): Observable<app.operation.ProjectTaskSelectListViewModel> {
        return this.http.get<app.operation.ProjectTaskSelectListViewModel>(this.url + "listitem/" + id);
    }

    getList(page?: number, pageSize?: number, searchTerm?: string, accountCenterCode?: string, branchId?: number,
        onlyActive?: boolean, countryCode?: string, onlyActiveAndWRShiftedEnabled?: boolean, onlyMetriqInput?:boolean): Observable<app.operation.ProjectTaskSelectListViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (branchId) {
            params = params.set("branchId", branchId.toString());
        }
        if (onlyActive) {
            params = params.set("onlyActive", onlyActive.toString());
        }
        if (countryCode) {
            params = params.set("countryCode", countryCode.toString());
        }
        if (onlyActiveAndWRShiftedEnabled) {
            params = params.set("onlyActiveAndWRShiftedEnabled", onlyActiveAndWRShiftedEnabled.toString());
        }
        if (onlyMetriqInput) {
            params = params.set("onlyMetriqInput", onlyMetriqInput.toString());
        }
        return this.http.get<app.operation.ProjectTaskSelectListViewModel[]>(this.url + "list", { params });
    }

    getFullList(page?: number, pageSize?: number, searchTerm?: string,
        accountCenterCode?: string, branchId?: number, onlyActive?: boolean, isOrderByDescending?: boolean): Observable<app.operation.ProjectTaskFullSelectListViewModel[]> {
        let params = new HttpParams();

        if (searchTerm) {
            params = params.set("searchTerm", searchTerm);
        }
        if (page) {
            params = params.set("page", page.toString());
        }
        if (pageSize) {
            params = params.set("pageSize", pageSize.toString());
        }
        if (accountCenterCode) {
            params = params.set("accountCenterCode", accountCenterCode);
        }
        if (branchId) {
            params = params.set("branchId", branchId.toString());
        }
        if (onlyActive) {
            params = params.set("onlyActive", onlyActive.toString());
        }
        if (isOrderByDescending) {
            params = params.set("isOrderByDescending", isOrderByDescending.toString());
        }
        return this.http.get<app.operation.ProjectTaskFullSelectListViewModel[]>(this.url + "list-full", { params });
    }

    getTypes(projectTaskId: number): Observable<app.operation.ProjectTaskTypeListViewModel> {
        return this.http.get<app.operation.ProjectTaskTypeListViewModel>(this.url + projectTaskId + "/types");
    }

    saveTypes(projectTaskId: number, types: app.operation.ProjectTaskTypeListViewModel) {
        return this.http.put(this.url + projectTaskId + "/types", types);
    }

    getNewWithProject(projectId: number): Observable<app.operation.ProjectTaskFormViewModel> {
        let params = new HttpParams();
        params = params.set("projectId", projectId.toString());
        return this.http.get<app.operation.ProjectTaskFormViewModel>(this.url + "new", { params });
    }

    lock(projectTaskId: number) {
        return this.http.put(this.url + projectTaskId + "/lock", "");
    }

    unlock(projectTaskId: number) {
        return this.http.put(this.url + projectTaskId + "/unlock", "");
    }

    activate(projectTaskId: number) {
        return this.http.put(this.url + projectTaskId + "/activate", "");
    }

    deactivate(projectTaskId: number) {
        return this.http.put(this.url + projectTaskId + "/deactivate", "");
    }

    getWithIssues(projectTaskId: number): Observable<app.operation.ProjectTaskInsertUpdateWithIssueListViewModel> {
        return this.http.get<app.operation.ProjectTaskInsertUpdateWithIssueListViewModel>(this.url + projectTaskId + "/getWithIssues");
    }

    getPartNumbersByProjectTaskId(projectTaskId: number): Observable<string[]> {
        return this.http.get<string[]>(this.url + projectTaskId + "/getNewest");
    }

    setWorkReportsShifted(projectTaskId: number) {
        return this.http.put(this.url + projectTaskId + "/setWorkReportsShifted", "");
    }

    getWorkReportUrl(projectTaskId: number, format?: string) {
        return this.url + projectTaskId + `/workreport?format=${format || "xlsx"}`;
    }

    getOrderUrl(projectTaskId: number, languageId: string) {
        return this.url + projectTaskId + `/order?languageId=${languageId}`;
    }

    getBozpUrl(projectTaskId: number) {
        return this.url + projectTaskId + "/bozp";
    }

    getWorkingProcedureUrl(projectTaskId: number, languageId: string, template: string, secondPage: boolean) {
        return this.url + projectTaskId + `/working-procedure?languageId=${languageId}&template=${template}&secondPage=${secondPage}`;
    }

    getWorkingProcedureSupplementUrl(projectTaskId: number, template: string) {
        return this.url + projectTaskId + `/working-procedure-supplement?template=${template}`;
    }

    getWorkingProcedureTemplateUrl(template: string) {
        return this.url + `working-procedure-template?template=${template}`;
    }

    getHistory(page: number, pageSize: number, projectTaskId: number): Observable<af.sys.EntityHistoryViewModel[]> {
        let params = new HttpParams();
        params = params.set("page", page.toString());
        params = params.set("pageSize", pageSize.toString());
        return this.http.get<af.sys.EntityHistoryViewModel[]>(this.url + projectTaskId + "/history", { params });
    }

    getReportData(projectTaskId: number, languageId: string): Observable<app.operation.ProjectTaskReportViewModel> {
        let params = new HttpParams();
        params = params.set("languageId", languageId);
        return this.http.get<app.operation.ProjectTaskReportViewModel>(this.url + projectTaskId + "/report", { params });
    }

    getBozpUploadOptions(projectTaskId: number): FileUploadOptions {
        let options = new FileUploadOptions();
        options.url = this.url + projectTaskId + "/bozp";
        return options;
    }

    getWorkingProcedureUploadOptions(projectTaskId: number): FileUploadOptions {
        let options = new FileUploadOptions();
        options.url = this.url + projectTaskId + "/working-procedure";
        return options;
    }

    setIsReadyToInvoice(projectTaskId: number, hasForInvFiles: boolean, outdatedProductRateIsConfirmed: boolean = false) {
        let params = new HttpParams();
        params = params.set("hasForInvFiles", hasForInvFiles ? "true" : "false");
        params = params.set("outdatedProductRateIsConfirmed", outdatedProductRateIsConfirmed ? "true" : "false");
        return this.http.put<app.operation.IsReadyToInvoiceResponse>(this.url + projectTaskId + "/is-ready-to-invoice/", null, { params });
    }

    setIsNotReadyToInvoice(projectTaskId: number, invoiceRejectNote: string) {
        return this.http.put(this.url + projectTaskId + "/is-not-ready-to-invoice/", { invoiceRejectNote });
    }

    updateInvoiceNote(projectTaskId: number, invoiceNote: string) {
        return this.http.put(this.url + projectTaskId + "/invoicenote/", { invoiceNote });
    }

    metriqSyncByProjectTask(projectTaskId: number) {
        return this.http.put(this.url + projectTaskId + "/metriq-sync-by-projecttask", "");
    }

    setIsMarkedToInvoice(projectTaskId: number) {
        return this.http.put(this.url + projectTaskId + "/is-marked-to-invoice/", null);
    }

    setIsNotMarkedToInvoice(projectTaskId: number) {
        return this.http.put(this.url + projectTaskId + "/is-not-marked-to-invoice/", null);
    }

    setIsReadyToPartiallyInvoice(projectTaskId: number, hasForInvFiles: boolean) {
        let params = new HttpParams();
        params = params.set("hasForInvFiles", hasForInvFiles ? "true" : "false");
        return this.http.put(this.url + projectTaskId + "/is-ready-to-partially-invoice/", null, { params });
    }

    setIsNotReadyToPartiallyInvoice(projectTaskId: number, invoiceRejectNote: string) {
        return this.http.put(this.url + projectTaskId + "/is-not-ready-to-partially-invoice/", { invoiceRejectNote });
    }

    partNumberPartCountRecalc(projectTaskId: number, item: app.operation.ProjectTaskPartNumberViewModel) {
        let params = new HttpParams();
        params = params.set("showToast", "false");
        return this.http.put<app.operation.ProjectTaskPartNumberViewModel>(this.url + projectTaskId + "/part-number-recalc", item, { params });
    }

    partNumberPartCountRecalcAll(projectTaskId: number) {
        let params = new HttpParams();
        params = params.set("showToast", "false");
        return this.http.put<app.operation.ProjectTaskPartNumberViewModel[]>(this.url + projectTaskId + "/part-number-recalc-all", null, { params });
    }

    isComponentArchived(componentId: number, value: boolean) {
        let params = new HttpParams();
        params = params.set("isArchived", value ? "true" : "false");

        return this.http.put(this.url + componentId + "/archive-component/", null, { params });
    }

    updateQsPersons(projectTask: app.operation.ProjectTaskUpdateViewModel, projectTaskId: number) {
        return this.http.put<app.operation.ProjectTaskUpdateViewModel>(this.url + projectTaskId + "/updateQSOnly", projectTask);
    }

    insertComponent(model: app.operation.ProjectTaskPartNumberViewModel): Observable<any> {
        return this.http.post(this.url + "component", model);
    }

    validateBonus(request: app.operation.GetProjectTaskBonusValidationRequest, projectTaskId: number): Observable<number> {
        let params = new HttpParams();

        if (request.projectTaskId) {
            params = params.set("projectTaskId", request.projectTaskId.toString());
        }

        if (request.bonusFrom) {
            params = params.set("bonusFrom", getUnixDate(request.bonusFrom).toString());
        }

        if (request.bonusTo) {
            params = params.set("bonusTo", getUnixDate(request.bonusTo).toString());
        }

        return this.http.get<number>(this.url + projectTaskId + "/validatebonus", { params });
    }

    getTimes(interval: number = 15, cycles: number = 97, isMetriq: boolean) {        
        let times = [];

        for (let i = 0; i < cycles; i++) {
            const hours = Math.floor(i * interval / 60);
            const minutes = i * interval - hours * 60;

            let title = hours.toString() + ":";
            if (hours < 10) { title = "0" + title; }
            if (minutes < 10) { title = title + "0"; }
            title = title + minutes.toString();
            const val = i * interval;

            if (isMetriq && title === "24:00") {
                title = "23:59";
            }

            times.push({
                value: val,
                title
            });
        }

        return times;
    }

    getScanActions(projectTaskId: number) {
        return this.http.get(this.url + projectTaskId + "/scan-actions");
    }
}
