    <ng-container>
        <af-page-bar title="{{ 'hollen.clientapp.src.app.operation.presentation-item.presentation-item-create.component.new presentation item' | translate }}"></af-page-bar>
    </ng-container>

    <div class="form mb-3">
        <form name="createForm" novalidate="" #createForm="ngForm" [class.disabled-all]="isLoading">
            <div class="form-horizontal">
                <div class="form-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">                          
                                <label class="control-label col-md-2" for="RecordOrder">{{ 'hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.order' | translate }}</label>
                                <div class="col-md-10">
                                    <input class="form-control" [(ngModel)]="presentationItem.recordOrder" type="number" id="RecordOrder" name="RecordOrder" required="" value="" /><input name="__Invariant" type="hidden" value="RecordOrder" />
                                    <span><span *ngIf="(createForm.controls['RecordOrder']?.dirty || createForm.submitted) && createForm.controls['RecordOrder']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.order'} }}</span>
</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label col-md-2" for="Length">{{ 'hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.length' | translate }}</label>                         
                                <div class="col-md-10">
                                    <input class="form-control" [(ngModel)]="presentationItem.length" [disabled]="isPresentationConfirmationRequired" type="number" id="Length" name="Length" required="" value="" /><input name="__Invariant" type="hidden" value="Length" />
                                    <span><span *ngIf="(createForm.controls['Length']?.dirty || createForm.submitted) && createForm.controls['Length']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|hollen.operation.projecttaskpresentationiteminsertupdateviewmodel.length'} }}</span>
</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-actions">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="offset-md-1 col-md-11">
                                <af-file-upload name="File" [disabled]="createForm.invalid" [simple]="true" styleClass="btn btn-insert btn-primary me-2" *afHasPerm="{obj:objectKey, perm: 'Insert'}" (click)="onStartUpload()" (upload)="onUpload()" (success)="onSuccessUpload($event)" (error)="onErrorUpload($event)" [options]="uploadOptions">
                                        {{ 'hollen.clientapp.src.app.operation.presentation-item.presentation-item-create.component.upload file' | translate }}
                                    </af-file-upload>
                                    <a class="btn-back btn-warning btn" onclick="history.back()">{{ 'btn.back' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
