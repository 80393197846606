import { finalize, switchMap } from "rxjs/operators";
import { Component, ViewChild, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { forkJoin as observableForkJoin, Observable, of } from "rxjs";
import { PresentationItemService } from './presentation-item.service';
import { AppService } from '../../app.service';
import { ProjectTaskFileService } from '../project-task/project-task-file.service';
import { ListQueryParams } from '../../models';

export class QueryParams extends ListQueryParams {
    presentationItemTypeId: number = null;
    firstProjectTaskId: number = null;
    projectTaskId: number;
    onlyPresentation: boolean = false;
}

@Component({
    selector: "op-presentation-item-edit",
    templateUrl: "./presentation-item-edit.component.html"
})
export class PresentationItemEditComponent implements OnInit {
    @Input() presentationItemId: number;
    @Input() isPresentationConfirmationRequired: boolean;
    @Output() updated = new EventEmitter();
    @Output() deleted = new EventEmitter();
    queryParams = new QueryParams();

    @ViewChild(NgForm, { static: true }) editForm: NgForm;
    form = <app.operation.ProjectTaskPresentationItemInsertUpdateViewModel>{};
    presentationItem = <app.operation.ProjectTaskPresentationItemInsertUpdateViewModel>{};
    objectKey: string;
    isLoading = false;
    firstProjectTaskId: number;
    
    constructor(protected router: Router,
        protected route: ActivatedRoute,
        private appService: AppService,
        private presentationItemService: PresentationItemService,
        private projectTaskFileService: ProjectTaskFileService) {
        this.objectKey = presentationItemService.objectKey;
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadData();
    }

    loadData()
    {
        this.firstProjectTaskId = +this.appService.getParam("projectTaskId");

        let presentationItemId = 0;

        if (this.presentationItemId) {
            presentationItemId = this.presentationItemId;
        }   

        this.queryParams.projectTaskId = this.firstProjectTaskId;
        this.queryParams.onlyPresentation = true;

        this.isLoading = true;
        observableForkJoin(
            this.presentationItemService.get(this.firstProjectTaskId, presentationItemId, true),
            this.projectTaskFileService.query(this.queryParams)
            )
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(result => {          
                this.form = result[0];
                this.presentationItem = result[0];         
                this.presentationItem.fileUrl = result[1].data.find(x => x.uniqueId == this.presentationItem.fileUniqueId).fileUrl;             
            });

    }

    update() {

    if (this.editForm.invalid || this.isLoading) {
        return;
    }

    this.isLoading = true;

        this.presentationItemService.saveChanges(this.firstProjectTaskId, this.presentationItem.projectTaskPresentationItemId ,this.presentationItem).pipe(
        finalize(() => this.isLoading = false))
        .subscribe(res => {
            this.editForm.control.markAsPristine();
            this.updated.emit();
        });
    }

    delete() {
        this.isLoading = true;

        this.presentationItemService.deleteById(this.presentationItem.projectTaskPresentationItemId,this.presentationItem.firstProjectTaskId).pipe(
            finalize(() => this.isLoading = false))
            .subscribe((result) => {
                this.editForm.reset();
                if (this.presentationItemId) {
                    this.deleted.emit();
                }
                else {
                    this.router.navigate(["/operation/projectTask/" + this.presentationItem.firstProjectTaskId + "/presentation-item"]);
                }
            });
    }
}
