import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../../app.service";

import { EmployeeRelationshipService } from "./employee-relationship.service";
import { EmployeeService } from "../../operation/employee/employee.service";
import { ProjectTaskService } from "../../operation/project-task/project-task.service";
import { FormComponent } from "../../core";
import { finalize, switchMap } from "rxjs/operators";

@Component({
    selector: "op-employee-relationship-create",
    templateUrl: "./employee-relationship-create.component.html"
})
export class EmployeeRelationshipCreateComponent extends FormComponent {
    @Input() employeeId: number;
    @Output() inserted = new EventEmitter<app.operation.EmployeeRelationshipInsertUpdateViewModel>();

    isLoading: boolean;
    @ViewChild(NgForm, { static: true }) createEmployeeRelationshipForm: NgForm;
    employeeRelationship = <app.operation.EmployeeRelationshipInsertUpdateViewModel>{};
    objectKey: string;
    currentUser: app.UserViewModel;
    paramId: number;
    agencies: app.codebook.CodebookSelectViewModel[];
    majorProviders: app.codebook.CodebookSelectViewModel[];
    internalMajorProviders: app.codebook.CodebookSelectViewModel[];
    employeePositionTypes: app.codebook.CodebookSelectViewModel[];
    employeeContractTypes: app.codebook.CodebookSelectViewModel[];
    hollenCompanies: app.organization.HollenCompanySelectListViewModel[];
    cooperantRates: app.codebook.CodebookSelectViewModel[];
    users: af.usersystem.UserSelectListViewModel[];
    employee: app.operation.EmployeeFormViewModel;
    form: app.operation.EmployeeRelationshipFormViewModel;
    rateTypes: app.codebook.CodebookSelectViewModel[];

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private employeeRelationshipService: EmployeeRelationshipService,
        private employeeService: EmployeeService,
        private projectTaskService: ProjectTaskService,
        private appService: AppService,

    ) {
        super(router, "employeeRelationship");

        this.objectKey = employeeRelationshipService.objectKey;
    }

    contractTypes = {
        'contractor': 1,
        'student': 2,
        'retired': 3,
        'parttime': 4,
        'dovp': 5,
        'distancestudent': 6,
        'retiredcontractor': 7,
        'tpp': 8,
        'agency': 9,
        'assignedemployee': 10,
        'majorprovider': 11,
        'hqs': 12
    };

    ngOnInit() {
        super.ngOnInit();

        this.isLoading = true;
        this.paramId = +this.appService.getParam("employeeId");
        this.employeeService.get(this.employeeId, false).subscribe((result) => {
            this.employee = result;
        });
        this.employeeRelationshipService.getNew({ employeeId: this.employeeId }).subscribe((result) => {
            this.form = result;
            this.agencies = result.agencies;
            this.majorProviders = result.majorProviders;
            this.internalMajorProviders = result.internalMajorProviders;
            this.employeeContractTypes = result.employeeContractTypes;
            this.employeePositionTypes = result.employeePositionTypes;
            this.hollenCompanies = result.hollenCompanies;
            this.users = result.users;
            this.employeeRelationship = result.model;
            this.rateTypes = result.rateTypes;
            this.isLoading = false;
        });

    }

    insert(): void {
        if (this.createEmployeeRelationshipForm.invalid || this.isLoading) {
            return;
        }

        this.isLoading = true;

        this.onSave();
        this.employeeRelationship.employeeId = this.employeeId;

        this.employeeRelationshipService.insert(this.employeeRelationship)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((result) => {
                if (result.errorMessage) {
                    toastr.error(result.errorMessage);
                }
                if (this.employeeId) {
                    this.inserted.emit(result);
                }
                else {
                    this.router.navigate(["../", result.employeeRelationshipId], { relativeTo: this.route, replaceUrl: true });
                    this.clearState();
                }
            });

    }

    setEmployeePositionItem($event, $index) {
        this.employeeRelationship.employeePositionTypeIds[$index] = $event;
    }

    addEmployeePositionItem() {
        this.employeeRelationship.employeePositionTypeIds.push(0);
    }


    deleteEmployeePositionItem($index) {
        this.employeeRelationship.employeePositionTypeIds.splice($index, 1);
    }

    onContractTyperChange($event) {
        this.employeeRelationship.employeeContractTypeId = $event;

        this.employeeRelationship.majorProviderId = null;
        this.employeeRelationship.cooperantRateId = null;
        this.cooperantRates = [];
        this.employeeRelationship.rateTypeId = null;
        this.employeeRelationship.agencyId = null;
        this.employeeRelationship.islandCode = null;
        this.setInternalMajorProvider(this.employeeRelationship.employeeContractTypeId, this.employeeRelationship.branchHollenCompanyCode);
    }


    onHollenCompanyCodeChange(hollenCompanyCode) {
        this.employeeRelationship.branchHollenCompanyCode = hollenCompanyCode;
        this.employeeRelationship.islandCode == null;
        this.setInternalMajorProvider(this.employeeRelationship.employeeContractTypeId, this.employeeRelationship.branchHollenCompanyCode);
    }

    onMajorProviderChange($event) {
        this.employeeRelationship.majorProviderId = $event;
        if (this.employeeRelationship.majorProviderId == null) {
            this.employeeRelationship.cooperantRateId = null;
            this.cooperantRates = [];
        }
        this.loadCooperantRateTypes();
    }

    onStartDateChange($event) {
        this.employeeRelationship.startDate = $event;
        if (this.employeeRelationship.startDate == null) {
            this.employeeRelationship.cooperantRateId = null;
            this.cooperantRates = [];
        }
        this.loadCooperantRateTypes();
    }

    loadCooperantRateTypes() {
        if (this.employeeRelationship.majorProviderId != null && this.employeeRelationship.startDate != null) {
            this.employeeService.getCooperantRatesByMajorProvider(this.employeeRelationship.majorProviderId, this.employeeRelationship.startDate)
                .subscribe((result) => {
                    this.cooperantRates = result;
                });
        }
    }

    setInternalMajorProvider(employeeContractTypeId, hollenCompanyCode) {
        if (employeeContractTypeId != this.contractTypes.hqs)
            return;
        this.employeeRelationship.majorProviderId = this.internalMajorProviders.find(x => x.code == hollenCompanyCode)?.id;
    }

    get islands() {
        if (!this.form || !this.form.islands || !this.employee.model.accountCenterCode) {
            return []
        }

        return this.form.islands.filter(x => x.accountCenterCode == this.employee.model.accountCenterCode);
    }

}
