import { finalize } from "rxjs/operators";
import { Component, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormComponent } from "src/app/core";
import { FileUploadOptions } from '../../shared/components/file-upload/file-upload.service';
import { PresentationItemService } from './presentation-item.service';
import { ProjectTaskFileService } from '../project-task/project-task-file.service';

@Component({
    selector: "op-presentation-item-create",
    templateUrl: "./presentation-item-create.component.html"
})
export class PresentationItemCreateComponent extends FormComponent {
    @Input() firstProjectTaskId: number;
    @Input() isPresentationConfirmationRequired: boolean;
    @Output() inserted = new EventEmitter<number>();

    @ViewChild(NgForm, { static: true }) createForm: NgForm;
    presentationItem = {} as app.operation.ProjectTaskPresentationItemInsertUpdateViewModel;
    objectKey: string;
    uploadOptions: FileUploadOptions;

    constructor(protected router: Router, protected route: ActivatedRoute,
        private presentationItemService: PresentationItemService,
        private projectTaskFileService: ProjectTaskFileService ) {
        super(router);
        this.objectKey = presentationItemService.objectKey;

        this.uploadOptions = new FileUploadOptions();
        this.uploadOptions.url = presentationItemService.getUrl(this.firstProjectTaskId);
    }

    ngOnInit() {
        super.ngOnInit();  
    }

    loadData() {
        this.presentationItem = {} as app.operation.ProjectTaskPresentationItemInsertUpdateViewModel;
    }

    onStartUpload() {
        if (this.createForm.invalid) {
            return;
        }

        if (this.firstProjectTaskId) {
            this.presentationItem.firstProjectTaskId = this.firstProjectTaskId;
        }

        this.setUploadOptions();
    }

    onUpload() {
        this.isLoading = true;
    }


    setUploadOptions() {
        this.uploadOptions = this.projectTaskFileService.getUploadOptions(this.firstProjectTaskId, false, false,true);
    }
    
    onSuccessUpload($event) {
        const response = JSON.parse($event.response);
        this.presentationItem.fileName = "PP_" + $event.originalName;
        this.presentationItem.fileUniqueId = response.uniqueId;
        this.presentationItem.firstProjectTaskId = this.firstProjectTaskId;       
        this.isLoading = true;

        if (!this.presentationItem.length) {
            this.presentationItem.length = 1;
        }

        this.presentationItemService.save(this.firstProjectTaskId, this.presentationItem)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {
                this.createForm.form.markAsPristine();           
                this.inserted.emit(response);
                this.isLoading = false;
            });
    }

    onErrorUpload() {
        console.log("onErrorUpload");
        this.isLoading = false;
    }
}
