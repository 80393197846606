import { finalize } from "rxjs/operators";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

//import { PresentationItemService } from "./presentation-item.service";
import { ListQueryParams } from "src/app/models/list-query-params.model";
import { TableBaseComponent } from "src/app/core";
import { PresentationItemService } from './presentation-item.service';
import { ProjectTaskFileService } from '../project-task/project-task-file.service';

export class QueryParams extends ListQueryParams {
    presentationItemTypeId: number = null;
    firstProjectTaskId: number = null;
    projectTaskId: number;
    onlyPresentation: boolean = false;
}

@Component({
    selector: "op-presentation-item-list",
    templateUrl: "./presentation-item-list.component.html"
})

export class PresentationItemListComponent extends TableBaseComponent { //implements OnInit 
    @Input() firstProjectTaskId: number;
    @Output() contentChange = new EventEmitter();
    @Input() content: any;
    queryParams = new QueryParams();
    objectKey: string;
    model: PagedList<app.operation.ProjectTaskPresentationItemListViewModel>;
    modelFiles: PagedList<app.sharepoint.SharePointFileViewModel>;
    //filter = <app.operation.presentationitems.queries.getpresentationitemfilter.ViewModel>{};

    isSharepointEnabled = false;
    isLoadingSharePoint = false;
    sharePointUrl: string;

    constructor(router: Router, private presentationItemService: PresentationItemService, private projectTaskFileService: ProjectTaskFileService) {
        super(router);
        this.objectKey = presentationItemService.objectKey;
        this.queryParams.sortBy = this.sortBy = "recordOrder";
    }

    ngOnInit() {
        super.ngOnInit();

        this.isLoading = true;
        if (this.firstProjectTaskId) {
            this.queryParams.firstProjectTaskId = this.firstProjectTaskId;
        }

        this.loadData();
    }

    loadData() {     
        this.isLoading = true;
        this.queryParams.projectTaskId = this.queryParams.firstProjectTaskId;
        this.queryParams.onlyPresentation = true;

        this.presentationItemService
            .query(this.queryParams.firstProjectTaskId, this.queryParams).pipe(
                    finalize(() => (this.isLoading = false)))
            .subscribe(resp => {            
                this.model = resp;
                this.saveState(this.queryParams);

                this.projectTaskFileService.query(this.queryParams)
                    .pipe(finalize(() => this.isLoading = false))
                    .subscribe(res => {
                        this.modelFiles = res; 
                        this.content = { ...this.content, data: res };
                        this.contentChange.emit(this.content);

                        let items = [];
                        this.model.data.forEach(c => {
                            let found = this.modelFiles.data.find(b => b.uniqueId === c.fileUniqueId);
                            if (found) {
                                items.push({ ...c, fileUrl: found.fileUrl, linkUrl: found.linkUrl });
                            }
                        });

                        this.model = {
                            ...res,
                            data: items,
                            totalCount: this.model.totalCount,
                            page: this.model.page,
                            pageSize: this.model.pageSize
                        };
                    });         
                });
    }
}
